/* eslint-disable */
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const ServerDataContext = React.createContext();

export const ServerDataProvider = props => {
  const value = useMemo(() => {
    return {
      data: props.value,
    };
  }, [props.value]);

  return <ServerDataContext.Provider value={value}>{props.children}</ServerDataContext.Provider>;
};

export const useServerData = fn => {
  const context = useContext(ServerDataContext);

  if (!context) {
    throw new Error('useServerData() must be a child of <ServerDataProvider />');
  }

  if (fn) {
    return fn(context.data);
  } else {
    return context.data;
  }
};

ServerDataContext.Provider.propTypes = {
  value: PropTypes.shape({
    config: PropTypes.shape({
      footerService: PropTypes.string,
      headerCommon: PropTypes.string,
      commonBundle: PropTypes.string,
      sportsbookUrl: PropTypes.string,
      topBets: PropTypes.string,
      cookiesContentUrl: PropTypes.string,
      slider: PropTypes.shape({}),
      supportedLocales: PropTypes.arrayOf(PropTypes.string),
      locale: PropTypes.string,
      devices: PropTypes.shape({}),
      environment: PropTypes.string,
    }),
    locale: PropTypes.string,
    domain: PropTypes.string,
    isMobile: PropTypes.bool,
    isAndroid: PropTypes.bool,
    isIOS: PropTypes.bool,
    nologin: PropTypes.bool,
    shouldRedirect: PropTypes.bool,
    slides: PropTypes.shape({
      showBullets: PropTypes.bool.isRequired,
      slides: PropTypes.arrayOf(
        PropTypes.shape({
          banner: PropTypes.string,
          subtitle: PropTypes.string,
          type: PropTypes.oneOf(['slide1', 'slide2']),
          channel: PropTypes.string.isRequired,
          tagline: PropTypes.string.isRequired,
          button: PropTypes.string.isRequired,
          terms: PropTypes.string.isRequired,
          termsLink: PropTypes.string.isRequired,
          termsLinkText: PropTypes.string.isRequired,
          textAlign: PropTypes.string.isRequired,
        })
      ).isRequired,
    }),
    pageData: PropTypes.arrayOf(
      PropTypes.shape({
        segment: PropTypes.shape({
          componentName: PropTypes.string,
          text: PropTypes.arrayOf(
            PropTypes.shape({
              count: PropTypes.string,
              header: PropTypes.string,
              paragraph: PropTypes.string,
            })
          ),
          imgUrl: PropTypes.string,
          imgMobileUrl: PropTypes.string,
          btn: PropTypes.shape({
            betHref: PropTypes.string,
            betText: PropTypes.string,
          }),
          appStoreBtn: PropTypes.shape({
            iosHref: PropTypes.string,
            androidHref: PropTypes.string,
          }),
        }).isRequired,
        locale: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
      })
    ),
    sliderData: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        img: PropTypes.string,
        imgMobile: PropTypes.string,
        h1: PropTypes.string,
        h2: PropTypes.string,
        h3: PropTypes.string,
        promoCode: PropTypes.string,
        button: PropTypes.shape({ text: PropTypes.string }),
        tc: PropTypes.shape({
          url: PropTypes.string,
          text: PropTypes.string,
        }),
      })
    ),
    termsConditions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        linkName: PropTypes.string.isRequired,
        linkUrl: PropTypes.string.isRequired,
      })
    ),
    moreVerticals: PropTypes.shape({
      title: PropTypes.string,
      verticalsList: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          image: PropTypes.shape({
            desktopUrl: PropTypes.string,
            mobileUrl: PropTypes.string,
          }),
          ctaTitle: PropTypes.string,
          promoCode: PropTypes.string,
        })
      ),
    }),
    footer: PropTypes.shape({
      notes: PropTypes.shape({
        note1: PropTypes.string,
        note2: PropTypes.string,
        note3: PropTypes.string,
      }),
    }),
    serverError: PropTypes.bool,
  }),
};
