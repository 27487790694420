import { useEffect } from 'react';
import { isItalyLocale, isSpainLocale } from './locales';
import { emitLoginOpen } from './analytics';

const getLoginButtonElem = () => document.querySelector('.action-login__button');
const getHeaderElem = () => document.getElementById('header-root');

const initObserver = (target, callback) => {
  const observer = new MutationObserver(callback);
  observer.observe(target, { childList: true, subtree: true });

  return observer;
};

const onButtonDetected = button => {
  button.addEventListener('click', () => {
    emitLoginOpen();
  });
};

const useLoginTracking = locale => {
  if ((!isItalyLocale(locale) && !isSpainLocale(locale)) || typeof document === 'undefined') return;

  useEffect(() => {
    const button = getLoginButtonElem();
    if (button) {
      onButtonDetected(button);
      return () => {};
    }

    const headerElem = getHeaderElem();
    if (!headerElem) return () => {};

    const mutationObserver = initObserver(headerElem, (_, observer) => {
      const button = getLoginButtonElem();
      if (button) {
        onButtonDetected(button);
        observer.disconnect();
      }
    });

    return () => mutationObserver.disconnect();
  }, []);
};

export default useLoginTracking;
