/* eslint-disable no-console */

import window from 'global/window';
import { Store } from '@wh/store';
import { TrackingLogic, TOPICS } from '@wh/tracking-logic';
import { subscribe, publish } from '@wh/message-bus';
import { getCookie } from './cookies';

const COOKIES = {
  CURRENCY_CODE: 'ddl_currency_code',
  CUSTOMER_ACCOUNT_NUMBER: 'ddl_customer_account_number',
  LANDING_URL: 'ddl_landing_document_location',
};

const TRACKING_PROFILES = {
  // option to load special utag file for specific locale, example below will load http://tags.tiqcdn.com/utag/williamhillgroup/at-web/dev/utag.js for locale 'de-at':
  // 'de-at': 'at-web',
};

const getSiteLocale = () => window.location.pathname.replace('/', '');
const getPageTitle = () => `www|${getSiteLocale()}`;
const getCustomerNumber = () => getCookie(COOKIES.CUSTOMER_ACCOUNT_NUMBER) || null;
const getCurrencyCode = () => getCookie(COOKIES.CURRENCY_CODE) || null;
const getLandingDocumentLocation = () => getCookie(COOKIES.LANDING_URL) || null;

// This option should be set only if locale uses a specific tag manager profile
export const getTrackingProfile = (trackingProfiles, locale) => trackingProfiles[locale] || undefined;

export const emitLoginOpen = env => {
  if (env !== 'prod') {
    window.AnalyticsTactical.Helpers.setCookie('activateGATag', 'true');
  }

  window.utag.link({
    tealium_event: 'login_open',
    NonInteractionEvent: '0',
    EventCategory: 'Account Management',
    EventAction: 'Login Interactions',
    EventLabel: 'Login Open',
  });
};

const getBaseTrackingData = (isLoggedIn = false) => {
  const customerNumber = getCustomerNumber();

  const data = {
    AppID: null,
    AppName: null,
    AppUserId: null,
    AppVersion: null,
    ApplicationType: 'Website',
    CurrencyCode: getCurrencyCode(),
    CustomerAccountNumber: getCustomerNumber(),
    EventIdFromUrl: null,
    LandingDocumentLocation: getLandingDocumentLocation(),
    PageEventName: null,
    PageTitle: getPageTitle(),
    PageUrl: window.location.href,
    ProductContext: null,
    SiteLanguage: getSiteLocale(),
    SiteSection: 'Splashpage',
    SiteSectionCategory: null,
    SiteSectionSubCategory: null,
    SiteSubSection: null,
    UserStatus: customerNumber || isLoggedIn ? 'Customer' : 'Non-Customer',
    LoginStatus: isLoggedIn ? 'Logged In' : 'Not logged in',
  };

  return Object.keys(data).map(key => ({
    key,
    value: data[key],
  }));
};

function sortObjectKeys(object) {
  return Object.keys(object)
    .sort()
    .reduce((acc, key) => {
      acc[key] = object[key];
      return acc;
    }, {});
}

const subscribeToErrorsTopic = () => {
  subscribe({
    topic: TOPICS.EVENT_ERROR,
    callback: msg => {
      const errorDetails = msg.data.errors[0].params[0];
      const errorDetailsMsg = errorDetails.expectedType
        ? `${errorDetails.name} expected to be type of ${errorDetails.expectedType}`
        : msg.data.errors[0].params.join(',');

      const error = new Error(`${msg.data.errors[0].message} (${errorDetailsMsg})`);
      console.error(error);
    },
  });
};

const subscribeToTrackingTopic = topic => {
  subscribe(topic, (e, i) => {
    console.groupCollapsed(`%c${i.event.tealium_event} -> Tracking Kit`, 'color:green');
    console.table(i.event && sortObjectKeys(i.event));
    console.groupEnd(`%c${i.event} -> Tracking Logic`, 'color:green');
  });
};

const subscribeToPartialTopic = () => {
  subscribe(TOPICS.EVENT_PARTIAL, e => {
    console.log('partial');
    console.log(e);
  });
};

const sendEventToTrackingLogic = (name, payload) => {
  publish({
    topic: TOPICS.EVENT,
    data: { event: name, payload },
  });
};

const getJurisdiction = locale => {
  const switchMap = {
    'it-it': 'it',
    'es-es': 'es',
  };

  return switchMap[locale] ?? 'uk';
};

const init = (env, isLoggedIn = false, locale) => {
  const store = Store.getInstance('business');
  const isProd = env === 'prod';
  const profile = getTrackingProfile(TRACKING_PROFILES, locale);
  const environment = isProd ? 'prd' : 'dev';
  const jurisdiction = getJurisdiction(locale);
  const vertical = TrackingLogic.Vertical.SPORTS;
  const options = {
    vertical,
    environment,
    jurisdiction,
    profile,
  };
  const trackingLogic = TrackingLogic.getInstance(store, options);
  const trackingKitTopic = trackingLogic.getTopics().trackingKit;

  subscribeToErrorsTopic();
  if (!isProd) {
    subscribeToTrackingTopic(trackingKitTopic);
    subscribeToPartialTopic();
  }

  const baseTrackingData = getBaseTrackingData(isLoggedIn);

  store.setEntries(baseTrackingData);

  sendEventToTrackingLogic('pageview', {});
};

export default init;
