const feedbackMessageBus = () => {
  const feedback = window.WH.messageBus.subscribe('feedback.open', () => {
    if (typeof window.usabilla_live === 'function') {
      window.usabilla_live('click');
    }
  });

  return () => {
    window.WH.messageBus.unsubscribe(feedback);
  };
};

export default feedbackMessageBus;
