import { useEffect } from 'react';
import window from 'global/window';
import { openLoginModal, openRegistrationModal } from '.';

const queryToCallbackMapping = paramName =>
  ({
    login: openLoginModal,
    register: openRegistrationModal,
  }[paramName]);

export const useQueryParams = (paramName, clearParamAfterUse = true) => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryParamValue = searchParams.get(paramName);
    const queryCallback = queryToCallbackMapping(queryParamValue);

    if (queryCallback) queryCallback();

    if (clearParamAfterUse) {
      searchParams.delete(paramName);

      const searchParamsString = searchParams.toString();
      const newURL = `${window.location.pathname}${searchParamsString ? `?${searchParamsString}` : ''}`;

      window.history.replaceState(null, '', newURL);
    }
  }, []);
  return null;
};

export default useQueryParams;
