import window from 'global/window';
import { subscribe, publish } from '@wh/message-bus';
import { TrackingLogic, COOKIES } from '@wh/tracking-logic';
import { Store } from '@wh/store';

const TRACKING_TOPIC = 'wh.analytics.command.interaction.track';

const getPageTitle = () => {
  const [host] = window.location.hostname.split('.');
  const path = window.location.pathname.split('/').filter(Boolean);
  return [host, ...path].join('|');
};

const setCookie = (name, value, expires, domain) => {
  document.cookie = `${name}=${value};expires=${expires};domain=.${domain}`;
};

const getCookie = name => {
  const cookies = document.cookie.split(';').map(cookie => cookie.trim());
  const filteredCookies = cookies.filter(cookie => cookie.startsWith(name));
  const cookieValues = filteredCookies.map(cookie => cookie.split('=')[1]);
  return cookieValues.pop();
};

const getLandingDocumentLocation = () => getCookie(COOKIES.LANDING_DOCUMENT_LOCATION);

const getBaseTrackingData = (isLoggedIn = false, isMobile) => ({
  AppID: null,
  AppName: null,
  AppUserId: null,
  AppVersion: null,
  ApplicationType: 'Website',
  CurrencyCode: null,
  CustomerAccountNumber: null,
  EventIdFromUrl: null,
  LandingDocumentLocation: getLandingDocumentLocation(),
  PageEventName: null,
  PageTitle: getPageTitle(),
  PageUrl: window.location.href,
  ProductContext: null,
  ProductSource: isMobile ? 'SM' : 'SX',
  SiteLanguage: 'en-gb',
  SiteSection: 'Splashpage',
  SiteSectionCategory: null,
  SiteSectionSubCategory: null,
  SiteSubSection: null,
  UserStatus: isLoggedIn ? 'Customer' : 'Non-Customer',
  LoginStatus: isLoggedIn ? 'Logged In' : 'Not logged in',
});

const getLoginTrackingData = (isLoggedIn = false) => ({
  ElementType: 'Componentised',
  EventAction: 'Login Interactions',
  EventCategory: 'Account Management',
  NonInteractionEvent: '0',
  LoginStatus: isLoggedIn ? 'Logged In' : 'Not logged in',
});

const setLandingDocumentLocation = () => {
  /*
   * We set this cookie so that 'Tracking Logic' package will find it and use the original vertical url
   * otherwise 'Tracking Logic' will try to set this cookie whenever it's called
   * this package is also used in 'Registration', so if it doesn't find this cookie,
   * it will set it inside the IFrame
   * which means that cookie's value is wrong and Analytics already noticed this
   */
  setCookie(COOKIES.LANDING_DOCUMENT_LOCATION, window.location.href, 'Session', 'www.williamhill.com');
};

export const loginComplete = (payload, isMobile) => {
  publish(TRACKING_TOPIC, {
    type: 'link',
    event: {
      tealium_event: 'login_successful',
      AccountBalance: null,
      EventLabel: 'Login Successful',
      LoginMethod: 'Password',
      ...getLoginTrackingData(true),
      ...getBaseTrackingData(true),
      ...payload,
      isMobile,
    },
  });
};

export const trackLoginOpen = (payload, isMobile) => {
  publish(TRACKING_TOPIC, {
    type: 'link',
    event: {
      tealium_event: 'login_open',
      EventLabel: 'Login Open',
      ...getLoginTrackingData(),
      ...getBaseTrackingData(),
      ...payload,
      isMobile,
    },
  });
};

export const trackLoginError = (payload, isMobile) => {
  publish(TRACKING_TOPIC, {
    type: 'link',
    event: {
      tealium_event: 'login_unsuccessful',
      EventLabel: 'Login Unsuccessful',
      EffortAttempt: payload.EffortAttempt.toString(),
      ErrorCode: payload.ErrorCode,
      ErrorFields: 'Username or Password',
      ErrorFieldsAmount: '1',
      ErrorMessages: 'Wrong username or password entered',
      LoginOption: 'Manual Login',
      LoginMethod: 'Password',
      ...getLoginTrackingData(),
      ...getBaseTrackingData(),
      ...payload,
      isMobile,
    },
  });
};

export const trackLoginRecovery = (payload, isMobile) => {
  publish(TRACKING_TOPIC, {
    type: 'link',
    event: {
      tealium_event: 'login_forgot_details',
      EventLabel: 'Forgot Details',
      ...getLoginTrackingData(),
      ...getBaseTrackingData(),
      ...payload,
      isMobile,
    },
  });
};

export const trackPageView = () => {
  publish(TRACKING_TOPIC, {
    type: 'view',
    event: {
      tealium_event: 'pageview',
      ...getBaseTrackingData(),
    },
  });
};

const useLoginTrackingUK = (locale, environment, isMobile) => {
  // do not load tracking logic for other locales than UK and Ireland
  if (!(locale === 'en-uk' || locale === 'en-ir')) {
    return;
  }
  // do not load tracking logic for other environments than PROD
  if (environment !== 'prod') {
    return;
  }

  const businessStore = Store.getInstance('business');
  const options = {
    vertical: TrackingLogic.Vertical.SPORTS,
    environment,
    jurisdiction: 'uk',
    sendToTracking: true,
  };
  const trackingLogic = TrackingLogic.getInstance(businessStore, options);
  const trackingStore = trackingLogic.getTrackingStore();

  trackingStore.setEntries([
    { key: 'AppID', value: null },
    { key: 'AppName', value: null },
    { key: 'AppUserId', value: null },
    { key: 'AppVersion', value: null },
    { key: 'ApplicationType', value: 'Website' },
    { key: 'PageTitle', value: getPageTitle() },
    { key: 'PageUrl', value: window.location.href },
    { key: 'SiteLanguage', value: 'en-gb' },
    { key: 'SiteSection', value: 'Splashpage' },
    { key: 'SiteSubSection', value: null },
    { key: 'SiteSectionCategory', value: null },
    { key: 'SiteSectionSubCategory', value: null },
    { key: 'UserStatus', value: 'Non-Customer' },
    { key: 'LoginStatus', value: 'Not logged in' },
    { key: 'CurrencyCode', value: null },
    { key: 'CustomerAccountNumber', value: null },
    { key: 'EventIdFromUrl', value: null },
    { key: 'LandingDocumentLocation', value: getLandingDocumentLocation() },
    { key: 'PageEventName', value: null },
    { key: 'ProductContext', value: 'Splashpage' },
  ]);

  setLandingDocumentLocation();
  trackPageView();

  subscribe('wh.common.command.dli.tl.eventPartial', (_, { event, payload }) => {
    // eslint-disable-next-line default-case
    switch (event) {
      case 'login_successful':
        loginComplete(payload, isMobile);
        break;
      case 'login_open':
        trackLoginOpen(payload, isMobile);
        break;
      case 'login_unsuccessful':
        trackLoginError(payload, isMobile);
        break;
      case 'login_forgot_details':
        trackLoginRecovery(payload, isMobile);
        break;
    }
  });
};

export default useLoginTrackingUK;
