import { useEffect } from 'react';
import window from 'global/window';
import document from 'global/document';
import { userState, errorKey } from '../utils/constants';
import { getSessionState, getObSessionState, openLoginModal } from '.';

export const usePoolingUserLogin = (noLogin, onLoadingChange, redirectUrl) => {
  if (noLogin || !redirectUrl) {
    // don't wait for logging in
    // when there is no option to log in or if there is empty url to redirect to
    return null;
  }

  useEffect(() => {
    let busy = false;
    const interval = setInterval(() => {
      if (busy) {
        return;
      }

      const triggerSessionStateCheck = getSessionState();
      if (!triggerSessionStateCheck) {
        return;
      }

      busy = true;
      triggerSessionStateCheck
        .then(loginState => loginState?.state === userState.LOGGED_IN)
        .then(loggedIn => {
          if (!loggedIn) {
            throw new Error('not logged in');
          }

          // fade the screen before next steps: checking session and login-flow start
          onLoadingChange(true);
          return getObSessionState();
        })
        .then(loginSessionState => loginSessionState?.state === userState.SESSION_EXISTS)
        .then(sessionExists => {
          if (!sessionExists) {
            throw new Error('no session');
          }

          return openLoginModal();
        })
        .catch(({ error }) => {
          busy = false;
          if (error === errorKey.USER_ALREADY_LOGGED_IN) {
            const depositWindow = document.getElementsByClassName('deposit-modal__iframe');
            if (depositWindow.length === 0) {
              window.location.href = redirectUrl;
            }
          }
        });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return null;
};

export default usePoolingUserLogin;
