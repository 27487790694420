import window from 'global/window';
import { openRegistrationModal } from '../utils';

const handleRegistrationButtonClick = locale => evt => {
  const isRegistrationButton = Array.from(evt.target.closest('button')?.classList || []).includes(
    'action-registration__button'
  );
  if (!isRegistrationButton) return false;

  evt.preventDefault();
  evt.stopImmediatePropagation();

  const clickHandlerByLocale = {
    'fi-fi': () => {
      window.location.href = 'https://www.williamhill.eu/fi/register';
    },
    'it-it': () => {
      openRegistrationModal('SX');
    },
  };

  const callback = clickHandlerByLocale[locale];

  return typeof callback === 'function' ? callback() : () => null;
};

export default handleRegistrationButtonClick;
