import { featureKey } from './constants';

const getBootstrapper = () => window.WH?.bootstrapper;

export const openLoginModal = () => {
  const bootstrapper = getBootstrapper();
  if (bootstrapper) return bootstrapper.features[featureKey.LOGIN_FLOW].start();
  return false;
};

export const openRegistrationModal = (regSource, promoCode) => {
  const bootstrapper = getBootstrapper();
  if (!bootstrapper) return;

  const startRegistrationFlow = bootstrapper.features[featureKey.REGISTRATION_FLOW].start;
  if (regSource) startRegistrationFlow({ regSource, nui: promoCode });
  else startRegistrationFlow({ nui: promoCode });
};

export const getSessionState = () => {
  const bootstrapper = getBootstrapper();
  if (bootstrapper) return bootstrapper.features[featureKey.SESSION_MANAGER].getState();
  return false;
};

export const getObSessionState = () => {
  const bootstrapper = getBootstrapper();
  if (bootstrapper) return bootstrapper.features[featureKey.OBSESSION_SERVICE].getState();
  return false;
};
