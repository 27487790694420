import { useEffect } from 'react';
import window from 'global/window';
import document from 'global/document';
import { featureKey, userState } from '../utils/constants';

const useDepositRedirect = (locale, redirectUrl) => {
  if (locale !== 'ca-ca') {
    return;
  }

  let closeBtnIsWired = false;
  let continueBtnIsWired = false;
  let backToSiteBtnIsWired = false;
  useEffect(() => {
    const interval = setInterval(() => {
      const bootstraper = window?.WH?.bootstrapper;
      if (!bootstraper) {
        return null;
      }

      bootstraper.features[featureKey.SESSION_MANAGER]
        .getState()
        .then(loginState => loginState?.state === userState.LOGGED_IN)
        .then(loggedIn => loggedIn && bootstraper.features[featureKey.OBSESSION_SERVICE].getState())
        .then(loginSessionState => loginSessionState?.state === userState.SESSION_EXISTS)
        .then(sessionExists => {
          if (!sessionExists) {
            return;
          }

          const redirectAction = () => {
            window.location.href = redirectUrl;
          };
          const qdForm = document.querySelector('.qd-wrapper.opened');
          if (qdForm) {
            // if Quick Deposit Modal is Open
            const successPanel = qdForm.querySelector('.qd-success-screen__panel');
            if (!successPanel) {
              closeBtnIsWired = false;
              continueBtnIsWired = false;
              return;
            }

            const closeBtn = qdForm.querySelector('.qd__close-button');
            if (closeBtn && !closeBtnIsWired) {
              closeBtn.addEventListener('click', redirectAction);
              closeBtnIsWired = true;
            }

            const continueDiv = successPanel.querySelector('.qd-success-screen__continue-to-wh');
            if (continueDiv) {
              const continueBtn = continueDiv.querySelector('button');
              if (continueBtn && !continueBtnIsWired) {
                continueBtn.addEventListener('click', redirectAction);
                continueBtnIsWired = true;
              }
            }
          } else {
            // If Deposit Modal is Open
            const depositModalIframe = document.querySelector('.deposit-modal__iframe');
            if (!depositModalIframe || !depositModalIframe.contentDocument) {
              backToSiteBtnIsWired = false;
              return;
            }

            const submitSuccessSpan = depositModalIframe.contentDocument.querySelector('.submitSuccess');
            if (!submitSuccessSpan) {
              backToSiteBtnIsWired = false;
              return;
            }

            const backToSiteBtn = depositModalIframe.contentDocument.querySelector('.backToSiteBtn');
            if (backToSiteBtn && !backToSiteBtnIsWired) {
              backToSiteBtn.addEventListener('click', redirectAction);
              backToSiteBtnIsWired = true;
            }
          }
        });
      return null;
    }, 1000);

    return () => clearInterval(interval);
  }, []);
};

export default useDepositRedirect;
