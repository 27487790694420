import window from 'global/window';
import document from 'global/document';

const overrideRedirect = locale => {
  if (locale === 'ca-ca') {
    // no need to override for canada page (default redirect to /betting/en-gb is ok)
    return;
  }

  // find all elements that redirect to sportsbook and swap their hrefs
  const headerLogo = document.querySelector('.sb-header-logo');
  if (headerLogo) headerLogo.setAttribute('href', window.location.href);

  function setHrefAttribute(mutations, observer) {
    const { target } = mutations[0];
    observer.disconnect();
    mutations.forEach(mutation => {
      target.setAttribute(mutation.attributeName, window.location.href);
    });
    observer.observe(target, { attributes: true });
  }

  if (headerLogo) {
    new MutationObserver(setHrefAttribute).observe(headerLogo, { attributes: true });
  }

  // as sometimes that's not enough, also programmatically override the behaviour
  document.addEventListener(
    'click',
    e => {
      if (e.target.closest('.sb-header-logo')) {
        e.stopPropagation();
        e.preventDefault();
        window.location = window.location.href;
      }
    },
    true
  );
};

export default overrideRedirect;
