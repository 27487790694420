import window from 'global/window';

const getCookie = key => {
  const myCookie = document.cookie.split(';').filter(cookie => cookie.indexOf(`${key}=`) > -1);

  if (myCookie.length) {
    return myCookie[0].replace(`${key}=`, '').trim();
  }
  return undefined;
};

const isCookieSet = key => {
  return typeof getCookie(key) !== 'undefined';
};

const setCookie = (key, payload = '', expiration = 0) => {
  const domain = window.location.hostname;

  let expData = '';
  if (expiration > 0) {
    const expDate = new Date(Date.now() + 1000 * expiration).toUTCString();
    expData = `expires=${expDate}`;
  }

  document.cookie = `${key}=${payload};path=/;domain=${domain};${expData}`;
};

export { getCookie, isCookieSet, setCookie };
