export const featureKey = {
  SESSION_MANAGER: 'session-manager',
  OBSESSION_SERVICE: 'obsession-service',
  LOGIN_FLOW: 'login-flow',
  REGISTRATION_FLOW: 'registration-flow',
};

export const userState = {
  LOGGED_IN: 'LOGGED_IN',
  SESSION_EXISTS: 'SESSION_EXISTS',
};

export const errorKey = {
  USER_ALREADY_LOGGED_IN: 'USER_ALREADY_LOGGED_IN',
};
