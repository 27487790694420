/* eslint-disable import/first */
import './publicPath';
import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import window from 'global/window';
import document from 'global/document';
import App from './components/App';

import './styles/index.scss';
import { ServerDataProvider } from './state/serverDataContext';

const serverData = window.__SERVER_DATA__;

Loadable.preloadReady().then(() => {
  ReactDOM.hydrate(
    <ServerDataProvider value={serverData}>
      <App />
    </ServerDataProvider>,
    document.getElementById('root')
  );

  document.querySelector('#server-data').remove();
  delete window.__SERVER_DATA__;
});
