import { useEffect } from 'react';
import { useServerData } from '../state/serverDataContext';

const getFooterContentElem = () => document.querySelector('.sbf-footer-layout');
const getFooterWrapperElem = () => document.getElementById('#footer');

const initObserver = (target, callback) => {
  const observer = new MutationObserver(callback);
  observer.observe(target, { childList: true, subtree: true });

  return observer;
};

const onFooterDetected = (footer, appStoreUrl) => {
  const link = footer.querySelector('[data-test-id="@sitebase/apps-item_footer-app-App-Store"] a');
  link.href = appStoreUrl;
};

const useOverwriteFooterValues = () => {
  const appStoreUrl = useServerData(data => data.pageData?.footerData?.appStoreUrl);

  useEffect(() => {
    if (!appStoreUrl) return () => {};

    const footer = getFooterContentElem();
    const footerWrapper = getFooterWrapperElem();

    if (footer) {
      onFooterDetected(footer, appStoreUrl);
      return () => {};
    }

    if (footerWrapper) {
      const mutationObserver = initObserver(footerWrapper, (_, observer) => {
        const footer = getFooterContentElem();
        if (footer) {
          onFooterDetected(footer, appStoreUrl);
          observer.disconnect();
        }
      });

      return () => mutationObserver.disconnect();
    }

    return () => {};
  }, [appStoreUrl]);
};

export default useOverwriteFooterValues;
