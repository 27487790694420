import { useEffect, useState } from 'react';

const HEADER_DEFAULT_HEIGHT = 44;

const getHeaderElem = () => document.getElementById('header-root');
const getHeaderPanels = () => Array.from(document.querySelectorAll('.sb-header-primary-panel, .sb-header__info-panel'));

const initObserver = (target, callback) => {
  const observer = new MutationObserver(callback);
  observer.observe(target, { childList: true, subtree: true });

  return observer;
};

const calculateNewHeight = headerElem => {
  const headerMargin = parseInt(getComputedStyle(headerElem).marginTop, 10);
  const newHeight = getHeaderPanels()
    .map(elem => elem.offsetHeight)
    .reduce((acc, curr) => acc + curr, 0);
  return newHeight + headerMargin;
};

const useHeaderHeight = () => {
  const [height, setHeight] = useState(HEADER_DEFAULT_HEIGHT);

  useEffect(() => {
    const headerElem = getHeaderElem();
    if (!headerElem) return () => {};

    setHeight(calculateNewHeight(headerElem));
    const mutationObserver = initObserver(headerElem, () => setHeight(calculateNewHeight(headerElem)));

    return () => mutationObserver.disconnect();
  }, []);

  return height;
};

export default useHeaderHeight;
