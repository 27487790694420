import React from 'react';

import './Spinner.scss';

const Spinner = () => (
  <div className="body-overlay">
    <div className="loading-spinner" />
  </div>
);

export default Spinner;
